import datatables from 'tools/datatables';
import dialog from 'tools/dialog';

import './homepage.scss';

export function homepage() {
  $(function () {
    initialize();
  });
}

function initialize() {
  myPrograms();
  ongoingPrograms();
  nextMonthDeparture();
  programStatus();
  ownersRevenue();
}

function myPrograms() {
  let options = {
    searching: false,
    paging: false,
    info: false,
    columnDefs: [
      { visible: false, targets: [4, 8, 10, 11] },
      { orderable: false, targets: '_all' },
    ],
  };
  options.columnDefs.push({ orderData: 11, targets: 8 });

  datatables.display('list', '.card.tp-myPrograms', options);
}

function nextMonthDeparture() {
  let options = {
    searching: false,
    paging: false,
    info: false,
    columnDefs: [
      { visible: false, targets: [4] },
      { orderable: false, targets: '_all' },
    ],
  };
  options.columnDefs.push({ orderData: 9, targets: 2 });

  datatables.display('list', '.card.tp-nextMonthDepartures', options);
}

function ongoingPrograms() {
  let options = {
    searching: false,
    paging: false,
    info: false,
    columnDefs: [
      { visible: false, targets: [4] },
      { orderable: false, targets: '_all' },
    ],
  };
  options.columnDefs.push({ orderData: 9, targets: 2 });

  datatables.display('list', '.card.tp-ongoingPrograms', options);
}

function programStatus() {
  let popupContent = $('#card-programs-status-for-whole-team').clone();
  $('#card-show-programs-status-for-whole-team').click(function () {
    dialog.open($(this).data('title'), popupContent, 700, true);
  });
}

function ownersRevenue() {
  let popupContent = $('#card-owners-revenue-for-whole-team').clone();
  $('#card-show-owners-revenue-for-whole-team').click(function () {
    dialog.open($(this).data('title'), popupContent, 700, true);
  });

  $('#card-show-owner-revenue').click(function () {
    $(this).toggle();
    $(this).prev().toggleClass('invisible');
  });
}
